import { format, parse } from 'date-fns'
import { Translate } from 'frr-web/lib/translation'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styled from 'styled-components'
import { ReactNode } from 'react'

dayjs.extend(relativeTime)

/*
 * Amount formats
 */

const Formatter = {
  long: new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
  }),
  short: new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }),
  list: new Intl.NumberFormat('de-CH', {
    currency: 'CHF',
    maximumFractionDigits: 2,
  }),
}

export const formatAmount =
  (type: 'short' | 'long' | 'list') =>
  (params: any): string =>
    params.value !== undefined && typeof params.value === 'number'
      ? Formatter[type].format(params.value)
      : ''

/*
 * Date formats
 */

export const formatDate = (params: { value?: string | Date | null }): string => {
  let dateString = ''

  if (params.value) {
    try {
      dateString = new Intl.DateTimeFormat('de-CH', {
        dateStyle: 'medium',
      }).format(new Date(params.value))
    } catch (error) {}
  }

  return dateString
}

export const formatListDateReveresed_DD_MM_YYYY = (params: { value?: string | null }): string => {
  let dateString = ''
  if (params.value && params.value > '') {
    dateString = formatDate({ value: parse(params.value, 'dd-MM-yyyy', new Date()) })
  }

  return dateString
}

export const formatListDateReveresed_YYYY_MM_DD = (params: { value?: string | null }): string => {
  let dateString = ''
  if (params.value && params.value > '') {
    dateString = formatDate({ value: parse(params.value, 'yyyy-MM-dd', new Date()) })
  }

  return dateString
}

export const formatDateParsed = (params: any): string => {
  const dateFormatted = formatDate(params)
  const dateParsed = formatDate({ value: parse(params.value, 'dd.MM.yyyy', new Date()) })
  return params.value ? dateParsed || dateFormatted : ''
}

export const formatDateTime = (params: any) =>
  params.value ? format(new Date(params.value), 'dd.MM.yyyy HH:mm') : ''

export const formatListDateTime = (params: { value: string }) => {
  try {
    return format(new Date(params.value), 'dd.MM.yy | HH:mm')
  } catch (e) {
    return params.value
  }
}

export const formatTime = (params: { value?: string | Date | null }) => {
  return params.value ? format(new Date(params.value), 'HH:mm:ss') : ''
}

export const formatTimeRelativeFromNow = (date: string | Date | undefined | null) => {
  return dayjs(date).fromNow()
}

/*
 * Duration formats
 */

// export enum DurationUnit {
//   Days = 'day',
//   Hours = 'hour',
//   Minutes = 'minute',
//   Seconds = 'second',
//   Milliseconds = 'millisecond',
// }

// const duration = (params: {
//   startDate?: string | Date | null
//   endDate?: string | Date | null
//   unit: DurationUnit
// }) => {
//   let duration = undefined
//   if (params.startDate && params.endDate) {
//     const endDate = dayjs(params.endDate)
//     duration = endDate.diff(params.startDate, params.unit)
//   }
//   return duration
// }

export const formatDuration =
  (type: 'days' | 'months', fallback?: string) =>
  (params: { value: any; translate: Translate }): string =>
    params.value
      ? type === 'days'
        ? params.translate('formFields.duration.days', {
            days: params.value,
          })
        : params.translate('formFields.duration.months', {
            months: params.value,
          })
      : fallback || ''

/*
 * Percentage
 */

export const formatPercentage = (params: { value: any }): string =>
  params.value ? `${(params.value * 100000) / 1000}%` : ''

/*
 * Name formats
 */

export const formatCustomerName = (customer: {
  baseInfo: { name: string | null; surname: string | null }
}): string => [customer.baseInfo.name, customer.baseInfo.surname].filter(Boolean).join(' ')

export const formatCustomerUserName = (customer: {
  firstName?: string | null
  lastName?: string | null
}): string => [customer.firstName, customer.lastName].filter(Boolean).join(' ')

/*
 * Partner variant / product formats
 */

export const formatPartnerVariantName = (params: {
  partnerVariant: {
    displayName: string | null
    externalIdentifier: string | null
    partnerSettings: { whitelabel: { onboardingChannel: string } }
  }
  t: Translate
}) =>
  `${
    params.partnerVariant.displayName ||
    params.t(
      `formFields.onboardingChannel.${params.partnerVariant.partnerSettings.whitelabel.onboardingChannel}`,
    )
  } ${
    params.partnerVariant.externalIdentifier
      ? ['(id: ', params.partnerVariant.externalIdentifier, ')'].join('')
      : ''
  }`

/*
 * String formats
 */

export const formatWithParentheses = (params: any): string => (params.value ? `(${params.value})` : '')

export const formatWithHyphen = (params: any): string => (params.value ? `- ${params.value}` : '')

export const formatLink = (params: { value: string; href: string }): ReactNode => (
  <StyledLink href={params.href}>{params.value}</StyledLink>
)

const StyledLink = styled.a`
  color: var(--color-primary);
  border-bottom: 1px solid transparent;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--color-hover);
    border-bottom: 1px solid var(--color-hover);
  }
`
